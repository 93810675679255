<template>
  <div class="contenedor-indicador">
    <Spinner 
      :mostrarSpinner="spinner"
    />

    <!-- Navegqación -> Titulo y boton <- -->
    <div class="indicador-head" v-if="mostrarBoton">
      <div class="indicador-body">
        <h1>Postulaciones internas</h1>
      </div>
    </div>

  <!-- Contenedor para la captura de información -->
    <div class="agregar-indicador" v-if="formularioDatos">

        <div class="contenedor-formulario">
              <form @submit.prevent="">


                <div class="input-label">
                  <label>Mujeres candidatas:</label>
                    <input 
                      type="String" 
                      v-model="contenedorData.mujeres"
                      class="form-control-my m-2"
                    >
                </div>

                <div class="input-label">
                  <label>Hombres candidatos:</label>
                    <input 
                    type="String" 
                    v-model="contenedorData.hombres"
                    class="form-control-my m-2"
                    >
                </div>

                <div class="input-label">
                  <label>Total candidaturas:</label>
                    <input 
                    type="String" 
                    v-model="contenedorData.totales"
                    class="form-control-my m-2"
                    >
                </div>
              
                <div class="input-label">
                  <label>Periodo:</label>
                    <input 
                    type="option" 
                    v-model="contenedorData.periodo"
                    class="form-control-my m-2"
                    >
                </div>

                <div class="input-label-large">
                  <label>Partido Político:</label>
                    <input 
                    type="String" 
                    v-model="contenedorData.partido"
                    class="form-control-my m-2"
                    >
                </div>
                <div class="input-label-large">
                    <label>Distrito:</label>
                    <select name="distritos" id="distritos" v-model="contenedorData.distrito">
                      <option value="">Selecciona un distrito</option>
                      <option value="Todos">Todos los distritos</option>
                      <option value="distrito-1">Distrito 1</option>
                      <option value="distrito-2">Distrito 2</option>
                      <option value="distrito-3">Distrito 3</option>
                      <option value="distrito-4">Distrito 4</option>
                      <option value="distrito-5">Distrito 5</option>
                      <option value="distrito-6">Distrito 6</option>
                      <option value="distrito-7">Distrito 7</option>
                      <option value="distrito-8">Distrito 8</option>
                      <option value="distrito-9">Distrito 9</option>
                      <option value="distrito-10">Distrito 10</option>
                      <option value="distrito-11">Distrito 11</option>
                      <option value="distrito-12">Distrito 12</option>
                    </select>
                    
                </div>

                  <div class="botones-accion">
                    <button class="btn btn-danger m-3" @click="limpiarForm" >Limpiar</button>
                    <button class="btn btn-primary" @click="addRegistro(contenedorData)">Agregar</button>
                  </div>
                    
              </form>
        </div>
        <div class="mensaje-registro" v-if="mensajeContenedor">
          {{ mensajeConfirmacion }}
        </div>      
    </div>

    <!-- Contenedor de información "Data Preview" -->

      <div class="datos-informacion"  v-if="fichaDatos">
      <div class="ficha-datos">
        <h1>Información capturada</h1>
        <p @click="regresar()">Regresar</p>
      </div>
      <button class="btn-boton-consultar" @click="mostrarInformacion()">Consultar</button>
      <div class="mostrar-ficha-informacion" v-if="viewInformacion">
         <div class="ficha-contenedor-datos" v-for="(item, index) in indicadores" :key="index">
        
              <div class="view-datos">
                <p>Periodo:  {{ item.periodo }} </p>
                <p>Distrito: {{ item.distrito }} </p>
              </div>
              <div class="view-datos">
                <p>Hombre: {{ item.hombres }} </p>
                <p>Mujeres: {{ item.mujeres }} </p>
              </div>
              <div class="view-datos">
                <button @click="eliminarRegistro(item._id)">Eliminar</button>
            </div>
        </div>
      </div>
    </div>
     
  </div>

</template>
<script>


import { defineAsyncComponent } from 'vue'

export default {

  components: {
    BotonAdd: defineAsyncComponent( () => import('../../../components/BotonAdd.vue')),
    Spinner: defineAsyncComponent( () => import('../../../components/Spinner.vue'))
  },
  data() {
    return {
      indicadorData: false,
      indicadorFormulario: false,
      diputaciones: {
        isLoading: true,
      },
      contenedorData:{
        mujeres: null,
        hombres: null,
        totales: null,
        periodo: null,
        partido: null,
        distrito: null,
        usuarioId: 'Administrador-01'
      },
      spinner: true,
      mostrarBoton: false,
      formularioDatos: false,
      fichaDatos: false,
      mensajeConfirmacion: null,
      mensajeContenedor: false,
      viewInformacion: false,
      indicadores: []
    }
  },
  methods: {
    mostrarMenu(){
      
    },
    stopSpinner(){

      setTimeout(() => {
        this.spinner = false
        this.mostrarBoton = true 
        this.indicadorFormulario = true
        this.formularioDatos = true
        this.fichaDatos = false
      },3000);
    },
    cerrarRegistro(){
      this.indicadorFormulario = false
      this.indicadorData = true
    },
    addRegistro( data ){
      const url = '/diputaciones/proceso-electoral/nuevo/postulaciones-internas'
      this.axios.post(`${ url }`, data )
        .then( res => {
          this.mensajeContenedor = true
          this.mensajeConfirmacion = 'Información agregada correctamente'
          this.limpiarForm()

          setTimeout(() => {
              this.mensajeContenedor = false
              this.mensajeConfirmacion = null
              this.formularioDatos = false
              this.fichaDatos = true
          }, 2000);
          
        })
        .catch( e => {
          console.log( e );
        })

    },
    limpiarForm() {
        this.contenedorData.hombres = ''
          this.contenedorData.mujeres = ''
          this.contenedorData.totales = ''
          this.contenedorData.distrito = ''
          this.contenedorData.periodo = ''
          this.contenedorData.partido = ''
    },
    regresar() {
      this.fichaDatos = false
      this.formularioDatos = true
    },
    mostrarInformacion() {
      this.viewInformacion = true
       const url = '/diputaciones/proceso-electoral/nuevo/postulaciones-internas'
           this.axios.get(`${url}`)
            .then( res => {
                return this.saveData( res.data )
            })
            .catch( e => {
                console.log( 'error: ' + e );
            }) 
    },
    saveData( data ) {
      this.indicadores = data 
    },
    eliminarRegistro( data ) {
      console.log( data );
    }
  },
  created(){
    this.stopSpinner()
  },
  

}
</script>

<style lang="scss">
  .ficha-data{
    width:80%;
    margin:20px auto;
    height:200px;
    background-color: #f1f1f1;
    .header-data{
      padding: 12px 12px;
      display:flex; align-items:center; justify-content: space-between;
    }
    .body-data{
      display: flex;  
    }
  }

  .agregar-indicador{
    width:70%;
    height: calc(100vh - 220px);
    margin:10px auto;
    padding:14px 12px;
    text-align:center;
    
    // background-color: red;
    & p{
      text-align:right;
      margin-right:40px;
      font-size:20px;
      font-weight: bold;
      cursor:pointer;

    }
    form{

      width:90%;
      margin:10px auto;
      display: flex;
      flex-direction: column;

      .input-label{
        
          label{
            width:700px;
            text-align:left;
            // background-color: red;
          }
          input { 
            width: 80px;
            height:50px;
            text-align:center;
          } 
      }

      .input-label-large{

          label{
            width:500px;
            text-align:left;
            // background-color: red;
          }

          input {
                width:280px; 
                height: 30px;
            }
            
          select {
                width:280px; 
                height: 30px;
                margin:20px auto;
          }
   
        }

        button {
          width:200px;
          margin:20px auto;
        }
      
      
    }

    .mensaje-registro {
      width:80%;
      height:50px;
      margin:10px auto;
      font-size:16px;
      font-weight: bold;
      background-color: #f1f1f1;
      display: flex; align-items:center; justify-content:center;
    }

    
  }

  
</style>